import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from './UserContext'; // Import UserContext
import './MatchesTable.css';
import { Link } from 'react-router-dom';

const FiveMatchesTable = () => {
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userInfo } = useContext(UserContext); // Get userInfo from context

    useEffect(() => {
        const fetchMatches = async () => {
            if (userInfo && userInfo.id) {
                try {
                    const response = await axios.get(`https://readit-readit-com.onrender.com/api/matches`, {
                        params: {
                            userId: userInfo.id
                        },
                    });
                    const data = response.data;
                    

                    // Ensure data is an array
                    if (Array.isArray(data)) {
                        setMatches(data);
                    } else {
                        setMatches([]); // Default to empty array if data is not an array
                        throw new Error("Unexpected data format");
                    }
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchMatches();
    }, [userInfo]);

    const handleViewAllMatchesClick = (event) => {
        if (!userInfo || !userInfo.id) {
            event.preventDefault(); // Prevent navigation
            alert("You need to register and log in to use this feature.");
        }
    };

    if (loading) return <div className="matches-table-container loading">Loading...</div>;


    return (
        <div className="matches-table-container">
            <h2 className="custom-h2">
                Discover Your Next Favorite Read With Profile Matches
            </h2>
            {userInfo && userInfo.id ? (
                error ? (
                    <div className="matches-table-container error">Error: {error}</div>
                ) : (
                    <div>
                        <table className="matches-table">
                            <thead>
                                <tr>
                                    <th>Match Count</th>
                                    <th>User Name</th>
                                    <th>Profile Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(matches) && matches.slice(0, 5).map(match => (
                                    <tr key={match.userId}>
                                        <td>{match.score}</td>
                                        <td>{match.username}</td>
                                        <td><Link to={`/user/${match.userId}`}>View Profile</Link></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            ) : (
                <div className="auth-message">
                    <p>Register and log in to use this feature.</p>
                </div>
            )}
            <div className="all-matches-link-container">
                <Link to="/matches" onClick={handleViewAllMatchesClick}>View All Matches</Link>
            </div>
        </div>
    );
};

export default FiveMatchesTable;
