import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import FiveMatchesTable from './FiveMatchesTable'; // Adjust path as needed
import './HomePage.css'; // Import CSS file for styling
import openBookImage from './images/open-book-27.jpg';
import { UserContext } from './UserContext'; // Adjust path as needed

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const { userInfo } = useContext(UserContext); // Access userInfo from context

  useEffect(() => {
    // Set loading to false after initial render
    setLoading(false);
  }, []);

  if (loading) return <div className="loading">Loading...</div>;

  return (
    <div className="home-page-container">
      <img src={openBookImage} alt="readit readit" className="centered-image" />
      <div className="text-container">
      <p>Simple. Wishlist a book if you want to read it.</p>
      <p>Add to <span style={{ color: '#d13525' }}>Read</span> after you <span style={{ color: '#d13525' }}>read it</span>.</p>
        <Link to="/wishlistTotals">
          <h3>Most <span style={{ color: '#0071c5' }}>Wishlisted</span> Books</h3>
        </Link>
        <Link to="/completedTotals">
          <h3>Most <span style={{ color: '#d13525' }}>Read</span> Books</h3>
        </Link>
        {userInfo && userInfo.username ? (
          <p>Welcome back, <strong>{userInfo.username}</strong></p>
        ) : (
          <p>Don't have an account? <Link to="/register">Register here</Link>.</p>
        )}
      </div>
      {userInfo && userInfo.username ? (
        <FiveMatchesTable />
      ) : (
        <div className="matches-placeholder">
          <h2>Discover Your Next Favorite Read With Profile Matches</h2>
          <p>Register and log in to use this feature.</p>
        </div>
        
      )}
       <div className="small_void"></div>
    </div>
  );
};

export default HomePage;
