const HalfStar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#eac784"
    width="20px"
    height="20px"
  >
    <path d="M12 17.27L7.82 21l1.64-7.03L2 9.24l7.19-.61L12 2v15.27z" />
  </svg>
);

export default HalfStar;