import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './WishlistTotals.css';

const WishlistTotals = () => {
  const [wishlistTotals, setWishlistTotals] = useState([]);
  const [allTotals, setAllTotals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeframe, setTimeframe] = useState('allTime');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  useParams();

  useEffect(() => {
    const fetchWishlistTotals = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`https://readit-readit-com.onrender.com/api/wishlistTotals?timeframe=${timeframe}`);
        if (response.ok) {
          const data = await response.json();
          const detailedData = await Promise.all(data.map(async item => {
            const bookResponse = await fetch(`https://readit-readit-com.onrender.com/api/book/id/${item.bookId}`);
            const bookData = await bookResponse.json();
            return {
              ...item,
              cover_url: bookData.book.cover_url // Assuming the book details include a `cover_url`
            };
          }));
          setAllTotals(detailedData);
          setWishlistTotals(detailedData.slice(0, itemsPerPage));
        } else {
          setError(new Error('Failed to fetch wishlist totals'));
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWishlistTotals();
  }, [timeframe, itemsPerPage]);

  const handleTimeframeChange = (newTimeframe) => {
    setTimeframe(newTimeframe);
    setCurrentPage(1);
  };

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const startIndex = currentPage * itemsPerPage;
    const newItems = allTotals.slice(startIndex, nextPage * itemsPerPage);
    setWishlistTotals([...wishlistTotals, ...newItems]);
    setCurrentPage(nextPage);
  };

  if (isLoading) {
    return <div className="wishlist-totals-container loading">Loading...</div>;
  }

  if (error) {
    return <div className="wishlist-totals-container error">Error: {error.message}</div>;
  }

  return (
    <div className="wishlist-totals-container">
      <h2>
        Most <span style={{ color: '#0071c5' }}>Wishlisted</span> Books
      </h2>
      <div className="timeframe-filters">
        <button
          className={timeframe === 'thisWeek' ? 'active' : ''}
          onClick={() => handleTimeframeChange('thisWeek')}
        >
          This Week
        </button>
        <button
          className={timeframe === 'thisMonth' ? 'active' : ''}
          onClick={() => handleTimeframeChange('thisMonth')}
        >
          This Month
        </button>
        <button
          className={timeframe === 'allTime' ? 'active' : ''}
          onClick={() => handleTimeframeChange('allTime')}
        >
          All Time
        </button>
      </div>
      <table className="wishlist-table">
        <thead>
          <tr>
            <th>Count</th>
            <th></th> 
            <th>Title</th>
            <th>Author</th>
          </tr>
        </thead>
        <tbody>
          {wishlistTotals.map(item => (
            <tr key={item.bookId}>
              <td>{item.count}</td>
              <td>
                <img src={item.cover_url} alt={item.title} className="book-cover" />
              </td>
              <td>
                <Link to={`/book/${item.bookId}`}>{item.title}</Link>
              </td>
              <td>{item.author_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-controls">
        {wishlistTotals.length < allTotals.length && (
          <button onClick={handleLoadMore}>Load More</button>
        )}
      </div>
      <div className="big_void"></div>
    </div>
  );
};

export default WishlistTotals;
