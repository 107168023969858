import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { UserContext } from './UserContext';
import './BookDetailPage.css';
import defaultCover from './images/default_cover.png';
import { format } from 'date-fns';
import FullStar from './icons/FullStar';
import HalfStar from './icons/HalfStar';
import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';

const BookDetailPage = () => {
  const { id } = useParams();
  const [book, setBook] = useState(null);
  const [error, setError] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reviewError, setReviewError] = useState(null);
  const { userInfo } = useContext(UserContext);
  const [wishlist, setWishlist] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [averageRating, setAverageRating] = useState(null);
  const [ratingCount, setRatingCount] = useState(0);

  useEffect(() => {
    const fetchBookDetails = async () => {
      try {
        const response = await axios.get(`https://readit-readit-com.onrender.com/api/book/id/${id}`);
        setBook(response.data.book);
        fetchReviews(response.data.book._id);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const calculateAverageRating = (reviews) => {
      if (reviews.length === 0) return 0;
      const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
      return Math.round((totalRating / reviews.length) * 10) / 10;
    };

    const fetchReviews = async (bookId) => {
      try {
        const response = await axios.get(`https://readit-readit-com.onrender.com/api/reviews/book/${bookId}`);
        const reviewsData = response.data;
        // Fetch user details for each review
        const reviewsWithUserDetails = await Promise.all(
          reviewsData.map(async (review) => {
            const userResponse = await axios.get(`https://readit-readit-com.onrender.com/api/users/${review.userId._id}`);
            const user = userResponse.data;
            return {
              ...review,
              username: user.username,
              profileImage: user.profileImage,
            };
          })
        );
    
        setReviews(reviewsWithUserDetails);
        setAverageRating(calculateAverageRating(reviewsWithUserDetails));
        setRatingCount(reviewsWithUserDetails.length);
      } catch (error) {
        setReviewError(error.message);
      }
    };
    

    fetchBookDetails();
  }, [id]);
  

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        if (userInfo && userInfo.id) {
          const response = await axios.get(`https://readit-readit-com.onrender.com/api/wishlist/${userInfo.id}`);
          setWishlist(response.data);
        }
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      }
    };

    const fetchCompleted = async () => {
      try {
        if (userInfo && userInfo.id) {
          const response = await axios.get(`https://readit-readit-com.onrender.com/api/completed/${userInfo.id}`);
          setCompleted(response.data);
        }
      } catch (error) {
        console.error('Error fetching completed items:', error);
      }
    };

    if (userInfo && userInfo.id) {
      fetchWishlist();
      fetchCompleted();
    }
  }, [userInfo]);

  const getBookId = () => {
    return book?._id || book?.key;
  };

  const handleAddToWishlist = async () => {
    if (!userInfo || !userInfo.id) {
      alert('Must be registered and logged in to use this feature.');
      return;
    }

    const bookId = getBookId();

    if (!book || !bookId) {
      alert('Book information is missing.');
      return;
    }

    try {
      
      const existingBook = wishlist.find(item => item.bookId === bookId);
      if (existingBook) {
        alert('This book is already in your wishlist!');
        return;
      }

      await axios.post('https://readit-readit-com.onrender.com/api/wishlist', {
        userId: userInfo.id,
        bookId,
        title: book.title,
        author_name: book.author_name?.join(', '),
        cover_url: book.cover_url
      });

      alert('Book added to wishlist successfully!');
      setWishlist(prevWishlist => [...prevWishlist, { bookId, title: book.title, author: book.author_name?.join(', '), cover_url: book.cover }]);
    } catch (error) {
      console.error('Error adding to wishlist:', error);
      alert(error.message);
    }
  };

  const handleAddToCompleted = async () => {
    if (!userInfo || !userInfo.id) {
      alert('Must be registered and logged in to use this feature.');
      return;
    }

    const bookId = getBookId();

    if (!book || !bookId) {
      alert('Book information is missing.');
      return;
    }

    try {
   
      const existingBook = completed.find(item => item.bookId === bookId);
      if (existingBook) {
        alert('This book is already in your completed list!');
        return;
      }

      await axios.post('https://readit-readit-com.onrender.com/api/completed', {
        userId: userInfo.id,
        bookId,
        title: book.title,
        author_name: book.author_name?.join(', '),
        cover_url: book.cover_url
      });

      alert('Book added to completed list successfully!');
      setCompleted(prevCompleted => [...prevCompleted, { bookId, title: book.title, author: book.author_name?.join(', '), cover_url: book.cover }]);
    } catch (error) {
      console.error('Error adding to completed:', error);
      alert(error.message);
    }
  };


  const handleHelpfulClick = async (reviewId) => {
    if (!userInfo || !userInfo.id) {
      alert('Must be registered and logged in to mark reviews as helpful.');
      return;
    }
  
    try {
      const response = await axios.post(`https://readit-readit-com.onrender.com/api/reviews/${reviewId}/helpful`, {
        userId: userInfo.id
      });

  
      setReviews(prevReviews => prevReviews.map(review =>
        review._id === reviewId ? { ...review, helpfulCount: response.data.helpfulCount, usersWhoClicked: [...review.usersWhoClicked, userInfo.id] } : review
      ));
    } catch (error) {
      console.error('Error marking review as helpful:', error);
      alert(error.message);
    }
  };


 const getHelpfulText = (count) => {
    if (count === 0) return '';
    return `${count} reader${count > 1 ? 's' : ''} found this helpful`;
  };

  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<FullStar key={`full-${i}`} className="star-icon" />);
    }
    if (halfStar) {
      stars.push(<HalfStar key="half" className="star-icon" />);
    }

    return <div className="star-rating">{stars}</div>;
  };

  if (loading) {
    return <div className="book-detail-container loading">Loading...</div>;
  }

  if (error) {
    return <div className="book-detail-container error">Error: {error.response ? error.response.data.message : error.message}</div>;
  }

  if (!book) {
    return <div className="book-detail-container">Book not found</div>;
  }

  return (
    <div className="book-detail-container">
      <h1 className="book-title">{book.title}</h1>
      <div className="book-info">
        <img
          src={book.cover_url || defaultCover}
          alt="Book Cover"
          className="book-details-cover"
        />
        <p><strong>Author:</strong> {book.author_name.join(', ')}</p>
        <p><strong>First Published:</strong> {book.first_publish_year}</p>
        {book.first_sentence && <p><strong>First Sentence:</strong> {book.first_sentence}</p>}
      </div>
      <div className="book-actions">
        <button className="add-to-wishlist" onClick={handleAddToWishlist}>Add to Wishlist</button>
        <button className="add-to-completed" onClick={handleAddToCompleted}>Add to Completed</button>
      </div>
      <div className="reviews-section">
        {averageRating !== null && averageRating > 0 && (
           <div className="average-rating">
           <span className="average-rating-number">{averageRating.toFixed(1)}</span><span className="rating-count">({ratingCount} {ratingCount === 1 ? 'rating' : 'ratings'})</span>
           {renderStars(averageRating)}
           
           <h4>Reviews for {book.title}</h4>
         </div>
        )}
        {reviewError && <div>Error fetching reviews: {reviewError}</div>}
        {reviews.length === 0 ? (
          <p>No reviews yet.</p>
        ) : (
        <ul>
          {reviews.map(review => (
            <li key={review._id} className="review-item">
              <p><strong>{review.reviewTitle}</strong></p>
              {renderStars(review.rating)}
              <CloudinaryContext cloudName="dk26qywhe">
              <Image 
              publicId={review.profileImage}
              className="user-review-pic">
              <Transformation width="80" crop="scale" />
              </Image>
            </CloudinaryContext>
              <p><Link to={`/user/${review.userId._id}`} className="review-username">{review.username}</Link></p>
              <small className="review-date">Reviewed on {format(new Date(review.timestamp), 'MMMM d, yyyy')}</small>
              <div className="mini_void"></div>
              <p>{review.reviewText}</p>

              <button
                onClick={() => handleHelpfulClick(review._id)}
                disabled={userInfo && review.usersWhoClicked.includes(userInfo.id)}
                className={userInfo && review.usersWhoClicked.includes(userInfo.id) ? 'button-helpful disabled' : 'button-helpful'}
              >
                {userInfo && review.usersWhoClicked.includes(userInfo.id) ? 'Your feedback is appreciated' : 'Helpful'}
              </button>
              {review.helpfulCount > 0 && <p className='helpfulText'>{getHelpfulText(review.helpfulCount)}</p>}
            </li>
          ))}
        </ul>

        )}
      </div>
    </div>
  );
};

export default BookDetailPage;
