export const Quotations = [
    { text: "Books are the quietest and most constant of friends; they are the most accessible and wisest of counselors, and the most patient of teachers.", author: "Charles W. Eliot" },
    { text: "Books are a uniquely portable magic.", author: "Stephen King" },
    { text: "A book is a dream that you hold in your hand.", author: "Neil Gaiman" },
    { text: "I've always thought that a good book should be either the entry point inward, to learn about yourself, or a door outward, to open you up to new worlds.", author: "Taylor Jenkins Reid" },
    { text: "Once you learn to read, you will be forever free.", author: "Frederick Douglass" },
    { text: "There are many little ways to enlarge your world. Love of books is the best of all.", author: "Jacqueline Kennedy" },
    { text: "Reading is a discount ticket to everywhere.", author: "Mary Schmich" },
    { text: "Books are a uniquely portable magic.", author: "Stephen King" },
    { text: "Books are the plane, and the train, and the road. They are the destination, and the journey. They are home.", author: "Anna Quindlen" },
    { text: "Reading is like thinking, like praying, like talking to a friend, like expressing your ideas, like listening to other people's ideas, like listening to music, like looking at the view, like taking a walk on the beach.", author: "Roberto Bolaño" },
    { text: "Reading was a joy, a desperately needed escape — I didn't read to learn, I was reading to read.", author: "Christian Bauman" },
    { text: "One glance at a book and you hear the voice of another person, perhaps someone dead for 1,000 years. To read is to voyage through time.", author: "Carl Sagan" },
    { text: "The world was hers for the reading.", author: "Betty Smith" },
    { text: "A book is a garden, an orchard, a storehouse, a party, a company by the way, a counselor, a multitude of counselors.", author: "Charles Baudelaire" },
    { text: "The books transported her into new worlds and introduced her to amazing people who lived exciting lives.", author: "Roald Dahl" },
    { text: "In books I have traveled, not only to other worlds, but into my own.", author: "Anna Quindlen" },
    { text: "Reading… a vacation for the mind…", author: "Dave Barry" },
    { text: "Reading is the sole means by which we slip, involuntarily, often helplessly, into another's skin, another's voice, another's soul.", author: "Joyce Carol Oates" },
    { text: "A reader lives a thousand lives before he dies, said Jojen. The man who never reads lives only one.", author: "George R.R. Martin" },
    { text: "Come to the book as you would come to an unexplored land. Come without a map. Explore it and draw your own map.", author: "Stephen King" },
    { text: "Keep reading. It's one of the most marvelous adventures that anyone can have.", author: "Lloyd Alexander" },
    { text: "Each book was a world unto itself, and in it I took refuge.", author: "Alberto Manguel" },
    { text: "Books are not about passing time. They're about other lives. Other worlds. Far from wanting time to pass, one just wishes one had more of it.", author: "Alan Bennett" },
    { text: "Books are the mirrors of the soul.", author: "Virginia Woolf" },
    { text: "Reading is the sole means by which we slip, involuntarily, often helplessly, into another's skin, another's voice, another's soul.", author: "Joyce Carol Oates" },
    { text: "Reading changes your life. Reading unlocks worlds unknown or forgotten, taking travelers around the world and through time. Reading helps you escape the confines of school and pursue your own education.", author: "Donalyn Miller" },
    { text: "We may sit in our library and yet be in all quarters of the earth.", author: "John Lubbock" },
    { text: "Books and doors are the same thing. You open them, and you go through into another world.", author: "Jeanette Winterson" },
    { text: "It is not true that we have only one life to live; if we can read, we can live as many more lives and as many kinds of lives as we wish.", author: "S.I. Hayakawa" },
    { text: "Books are the quietest and most constant of friends; they are the most accessible and wisest of counselors, and the most patient of teachers.", author: "Charles William Eliot" },
    { text: "What I love most about reading: It gives you the ability to reach higher ground. And keep climbing.", author: "Oprah" },
    { text: "Books are mirrors: you only see in them what you already have inside you.", author: "Carlos Ruiz Zafón" },
    { text: "No book is really worth reading at the age of ten which is not equally - and often far more - worth reading at the age of fifty and beyond.", author: "C.S. Lewis" },
    { text: "Reading furnishes the mind only with materials of knowledge; it is thinking that makes what we read ours.", author: "John Locke" },
    { text: "The best books… are those that tell you what you know already.", author: "George Orwell" },
    { text: "Show me a family of readers, and I will show you the people who move the world.", author: "Napoléon Bonaparte" },
    { text: "I think it's the books that you read when you're young that live with you forever.", author: "J.K. Rowling" },
    { text: "Maybe this is why we read, and why in moments of darkness we return to books: to find words for what we already know.", author: "Alberto Manguel" },
    { text: "Reading is an intelligent way of not having to think.", author: "Walter Moers" },
    { text: "I don't believe one reads to escape reality. A person reads to confirm a reality he knows is there, but which he has not experienced.", author: "Lawrence Durrell" },
    { text: "When you educate a woman, you set her free. Had I not had books and education in Mississippi, I would have believed that's all there was.", author: "Oprah" },
    { text: "You will learn most things by looking, but reading gives understanding. Reading will make you free.", author: "Paul Rand" },
    { text: "There are worse crimes than burning books. One of them is not reading them.", author: "Joseph Brodsky" },
    { text: "In the case of good books, the point is not to see how many of them you can get through, but rather how many can get through to you.", author: "Mortimer J. Adler" },
    { text: "Reading one book is like eating one potato chip.", author: "Diane Duane" },
    { text: "The reason that fiction is more interesting than any other form of literature, to those who really like to study people, is that in fiction the author can really tell the truth without humiliating himself.", author: "Eleanor Roosevelt" },
    { text: "A good book is an event in my life.", author: "Stendhal" },
    { text: "If you would tell me the heart of a man, tell me not what he reads, but what he rereads.", author: "François Mauriac" },
    { text: "Reading brings us unknown friends.", author: "Honoré Balzac" },
    { text: "I've always ready to figure out people more, and that includes good people and bad people.", author: "Gillian Flynn" },
    { text: "Literature is the most agreeable way of ignoring life.", author: "Fernando Pessoa" },
    { text: "What a blessing it is to love books as I love them; to be able to converse with the dead, and to live amidst the unreal!", author: "Thomas Babington Macaulay" },
    { text: "Once you have read a book you care about, some part of it is always with you.", author: "Louis L'Amour" },
    { text: "Wherever I go, bookstores are still the closest thing to a town square.", author: "Gloria Steinem" },
    { text: "To acquire the habit of reading is to construct for yourself a refuge from almost all the miseries of life.", author: "W. Somerset Maugham" },
    { text: "No matter who you are, no matter where you live, and no matter how many people are chasing you, what you don't read is often as important as what you do read.", author: "Lemony Snicket" },
    { text: "Take a good book to bed with you - books do not snore.", author: "Thea Dorn" },
    { text: "No entertainment is so cheap as reading, nor any pleasure so lasting.", author: "Mary Wortley Montagu" },
    { text: "I can feel infinitely alive curled up on the sofa reading a book.", author: "Benedict Cumberbatch" },
    { text: "We are all unreliable narrators-all of us.", author: "Ruth Ware" },
    { text: "A well-read woman is a dangerous creature.", author: "Lisa Kleypas" },
    { text: "Read a thousand books, and your words will flow like a river.", author: "Lisa See" },
    { text: "The reading of all good books is like conversation with the finest men of past centuries.", author: "René Descartes" },
    { text: "Books can be dangerous. The best ones should be labeled 'This could change your life.'", author: "Helen Exley" },
    { text: "My life is a reading list.", author: "John Irving" },
    { text: "Literature is my Utopia.", author: "Helen Keller" },
    { text: "Reading well is one of the great pleasures that solitude can afford you.", author: "Harold Bloom" },
    { text: "To know a man's library is, in some measure, to know a man's mind.", author: "Geraldine Brooks" },
    { text: "Reading is the sole means by which we slip, involuntarily, often helplessly, into another's skin, another's voice, another's soul.", author: "Joyce Carol Oates" },
    { text: "To read is to fly: it is to soar to a point of vantage which gives a view over wide terrains of history, human variety, ideas, shared experience and the fruits of many inquiries.", author: "A.C. Grayling" },
    { text: "A good book is an education of the heart. It enlarges your sense of human possibility what human nature is of what happens in the world. It's a creator of inwardness.", author: "Susan Sontag" },
    { text: "Books can be dangerous. The best ones should be labeled 'This could change your life.'", author: "Helen Exley" },
    { text: "Few things leave a deeper mark on the reader than the first book that finds its way to his heart.", author: "Carlos Ruiz Zafón" },
    { text: "You have to remember that it is impossible to commit a crime while reading a book.", author: "John Waters" },
    { text: "I love the solitude of reading. I love the deep dive into someone else's story, the delicious ache of a last page.", author: "Naomi Shihab Nye" },
    { text: "Read. Read anything. Read the things they say are good for you, and the things they claim are junk. You'll find what you need to find. Just read.", author: "Neil Gaiman" },
    { text: "What a blessing it is to love books.", author: "Elizabeth von Arnim" },
    { text: "To feel most beautifully alive means to be reading something beautiful, ready always to apprehend in the flow of language the sudden flash of poetry.", author: "Gaston Bachelard" },
    { text: "Any book that helps a child to form a habit of reading, to make reading one of his deep and continuing needs, is good for him.", author: "Maya Angelou" },
    { text: "I read my eyes out and can't read half enough… the more one reads the more one sees we have to read.", author: "John Adams" },
    { text: "I do not read a book; I hold a conversation with the author.", author: "Elbert Hubbard" },
    { text: "The problem with a life spent reading is you know too much.", author: "Josh Lanyon" },
    { text: "Some books are so familiar that reading them is like being home again.", author: "Louisa May Alcott" },
    { text: "Not all readers are leaders, but all leaders are readers.", author: "Harry Truman" },
    { text: "I think books are like people, in the sense that they'll turn up in your life when you most need them.", author: "Emma Thompson" },
    { text: "He that loves reading has everything within his reach.", author: "William Godwin" },
    { text: "The importance of reading, for me, is that it allows you to dream. Reading not only educates but is relaxing and allows you to feed your imagination - creating beautiful pictures from carefully chosen words.", author: "Chef Eric Ripert" },
    { text: "Reading is a gift. It's something you can do almost anytime and anywhere. It can be a tremendous way to learn, relax, and even escape.", author: "Richard Carlson" },
    { text: "I have never known any distress that an hour's reading did not relieve.", author: "Montesquieu" },
    { text: "Read. As much as you can. As deeply and widely and nourishingly and irritatingly as you can. And the good things will make you remember them, so you won't need to take notes.", author: "A.L. Kennedy" },
    { text: "When you lose yourself in a book, the hours grow wings and fly.", author: "Chloe Thurlow" },
    { text: "Literature is the art of discovering something extraordinary about ordinary people and saying with ordinary words something extraordinary.", author: "Boris Pasternak" },
    { text: "The world belongs to those who read.", author: "Rick Holland" },
    { text: "This week I've been reading a lot and doing little work. That's the way things ought to be. That's surely the road to success.", author: "Anne Frank" },
    { text: "Few things leave a deeper mark on the reader than the first book that finds its way to his heart.", author: "Carlos Ruiz Zafón" },
    { text: "Yeah, sure, I mean, if you spend all day shuffling words around, you can make anything sound bad, Morty.", author: "Rick" }
  ];
  