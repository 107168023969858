import React, { useState, useEffect, useContext, useCallback } from 'react';
import SearchBar from './SearchBar';
import axios from 'axios';
import { UserContext } from './UserContext';
import { Quotations } from './Quotations';
import './SearchPage.css'; // Import CSS file
import logo from './images/180_logo.png'; // Import your logo image


const SearchPage = () => {
  const { userInfo } = useContext(UserContext);
  const [randomQuote, setRandomQuote] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [wishlist, setWishlist] = useState([]);
  const [completed, setCompleted] = useState([]);

  const fetchWishlist = useCallback(async () => {
    try {
      if (userInfo && userInfo.id) {
        const response = await axios.get(`https://readit-readit-com.onrender.com/api/wishlist/${userInfo.id}`);
        setWishlist(response.data);
      }
    } catch (error) {
      console.error('Error fetching wishlist:', error);
    }
  }, [userInfo]);

  const fetchCompleted = useCallback(async () => {
    try {
      if (userInfo && userInfo.id) {
        const response = await axios.get(`https://readit-readit-com.onrender.com/api/completed/${userInfo.id}`);
        setCompleted(response.data);
      }
    } catch (error) {
      console.error('Error fetching completed items:', error);
    }
  }, [userInfo]);

  useEffect(() => {
    const initialQuote = generateRandomQuote();
    setRandomQuote(initialQuote);
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.id) {
      fetchWishlist();
      fetchCompleted();
    }
  }, [userInfo, fetchWishlist, fetchCompleted]);

  const generateRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * Quotations.length);
    return Quotations[randomIndex];
  };

  const handleSearch = async (query) => {
    setIsLoading(true);
    setError(null);
    try {
      // Check local database first
      const localResponse = await axios.get(`https://readit-readit-com.onrender.com/api/search-local/${encodeURIComponent(query)}`);
      const localData = localResponse.data;
      
      if (localData.length > 0) {
        setResults(localData);
      } else {
        // If no results in local database, query Open Library API
        const response = await axios.get(`https://readit-readit-com.onrender.com/api/books/${encodeURIComponent(query)}`);
        const data = response.data;
        
        // Extract necessary data and include itemId
        const resultsWithItemId = data.books.map(book => ({
          ...book,
          itemId: book.key // Assuming `book.key` corresponds to `itemId`
        }));
        
        setResults(resultsWithItemId.slice(0, 50)); // Limit results to first 50 books
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleAddToWishlist = async (book) => {
    if (!userInfo || !userInfo.id) {
      alert('Must be registered and logged in to use this feature.');
      return;
    }
  
    try {
      // Add or update the book in the database with essential fields and get the book's _id
      const bookResponse = await axios.post('https://readit-readit-com.onrender.com/api/add-book', {
        itemId: book.itemId, // Use itemId here
        title: book.title,
        author_name: book.author_name?.join(', '),
        cover_url: book.cover_url,
        first_publish_year: book.first_publish_year,
        first_sentence: book.first_sentence,
        isbn: book.isbn,
        author_key: book.author_key,
        subject: book.subject,
        subject_facet: book.subject_facet
      });
  
      const bookId = bookResponse.data._id;
  
      // Check if the book is already in the wishlist
      const existingBook = wishlist.find(item => item.bookId === bookId);
      if (existingBook) {
        alert('This book is already in your wishlist!');
        return;
      }
  
      // Add the book to the wishlist with the book's _id
      await axios.post('https://readit-readit-com.onrender.com/api/wishlist', {
        userId: userInfo.id,
        bookId: bookId,
        title: book.title,
        author_name: book.author_name?.join(', '),
        cover_url: book.cover_url
      });
  
      alert('Book added to wishlist successfully!');
      fetchWishlist(); // Update wishlist after adding a new book
    } catch (error) {
      console.error('Error adding to wishlist:', error);
      alert(error.message);
    }
  };
  
  const handleAddToCompleted = async (book) => {

    if (!userInfo || !userInfo.id) {
      alert('Must be registered and logged in to use this feature.');
      return;
    }

    try {
      // Add or update the book in the database with essential fields and get the book's _id
      const bookResponse = await axios.post('https://readit-readit-com.onrender.com/api/add-book', {
        itemId: book.itemId, // Use itemId here
        title: book.title,
        author_name: book.author_name?.join(', '),
        cover_url: book.cover_url,
        first_publish_year: book.first_publish_year,
        first_sentence: book.first_sentence,
        isbn: book.isbn,
        author_key: book.author_key,
        subject: book.subject,
        subject_facet: book.subject_facet
      });

      const bookId = bookResponse.data._id;

      // Check if the book is already in the completed list
      const existingBook = completed.find(item => item.bookId === bookId);
      if (existingBook) {
        alert('This book is already in your completed list!');
        return;
      }

      // Add the book to the completed list with the book's _id
      await axios.post('https://readit-readit-com.onrender.com/api/completed', {
        userId: userInfo.id,
        bookId: bookId,
        title: book.title,
        author_name: book.author_name?.join(', '),
        cover_url: book.cover_url
      });

      alert('Book added to completed list successfully!');
      fetchCompleted(); // Update completed list after adding a new book
    }
    catch (error) {
      console.error('Error adding to completed list:', error);
      alert(error.message);
    }
  }
  
  return (
    <div className="search-page">
      <div className="medium_void"></div>
      <div className="quote-box">
        <h4 className="quote-text">"{randomQuote.text}"</h4>
        <p className="quote-author">- {randomQuote.author}</p>
      </div>
      <div className="search-bar-container">
        <SearchBar onSearch={handleSearch} />
      </div>
      {isLoading && (
        <div className="loading-message">
          <img src={logo} alt="Loading" className="spinning-logo" />
          <p>Loading...</p>
        </div>
      )}
      {error && <div className="error-message">Error: {error.message}</div>}
      <div className="results-container">
        {results.length > 0 ? (
          <div className="results-content">
            <h2 className="results-title">Search Results</h2>
            {results.map((book) => (
              <div key={book.key} className="book-item">
                <h3 className="book-title">{book.title}</h3>
                <p className="book-author">Author: {book.author_name?.join(', ')}</p>
                <div className="book-container">
                  {book.cover_url ? (
                    <img src={book.cover_url} alt="Book Cover" className="book-cover" />
                  ) : (
                    <div className="book-cover-placeholder no-cover-placeholder">No Cover Available</div>
                  )}
                  <div className="book-actions">
                    <button className="add-to-wishlist" onClick={() => handleAddToWishlist(book)}>Add to Wishlist</button>
                    <button className="add-to-completed" onClick={() => handleAddToCompleted(book)}>Add to Completed</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <h4 className="no-results-message">Enter title, author, or keyword above.</h4>
        )}
      </div>
      {/* <div className="big_void"></div> */}
      <div className="medium_void"></div>
    </div>
  );
};

export default SearchPage;

