import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './UserContext';
import Register from './Register';
import Login from './Login';
import Profile from './Profile';
import UserProfile from './UserProfile';
import Header from './Header';
import SearchPage from './SearchPage';
import Completed from './Completed';
import Wishlist from './Wishlist';
import CompletedTotals from './CompletedTotals';
import WishlistTotals from './WishlistTotals';
import HomePage from './HomePage';
import AllMatchesTable from './AllMatchesTable';
import Footer from './Footer';
import BookDetailPage from './BookDetailPage';
import ConfirmEmail from './ConfirmEmail';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';


const App = () => {
  return (
    <UserProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/user/:userId" element={<UserProfile />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/completed" element={<Completed />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/completedTotals" element={<CompletedTotals />} />
          <Route path="/wishlistTotals" element={<WishlistTotals />} />
          <Route path="/matches" element={<AllMatchesTable />} />
          <Route path="/book/:id" element={<BookDetailPage />} />
          <Route path="/confirm-email" element={<ConfirmEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
        <Footer />
      </Router>
    </UserProvider>
  );
};

export default App;
