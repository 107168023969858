import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from './UserContext';
import './Login.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setUserInfo } = useContext(UserContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://readit-readit-com.onrender.com/api/login',
        { username, password },
        { withCredentials: true }
      );
      if (response.status === 200) {
        const { token, id } = response.data;
        localStorage.setItem('token', token);
        setUserInfo({ username, id });
        
        navigate('/');
      } else {
        const message = response.data.message || 'Login failed. Please try again.';
        window.alert(message); // Show alert for error
      }
    } catch (error) {
      const message = error.response?.data?.message || 'Login failed. Please try again.';
      console.error('Login failed:', error);
      window.alert(message); // Show alert for error
    }
  };

  return (
    <div className="login-container">
      <div className="medium_void"></div>
      <form className="login" onSubmit={handleLogin}>
        <h1>Login</h1>
        <div className="input-container">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="login-input"
          />
        </div>
        <div className="input-container">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="login-input"
          />
          <span
            className="toggle-password"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </span>
        </div>
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
      <p className="login-note">
      Don't have an account? <Link to="/register">Register here</Link>.</p>
      <p className="login-note">
        <Link to="/forgot-password">Forgot password?</Link>
      </p>
      <div className="medium_void"></div>
    </div>
  );
};

export default Login;
