import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from './UserContext'; // Import UserContext
import './AllMatchesTable.css';
import default_cover from './images/default_cover1.png'; // Import the image
import { Link } from 'react-router-dom';

const AllMatchesTable = () => {
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const matchesPerPage = 20;
    const { userInfo } = useContext(UserContext); // Get userInfo from context

    useEffect(() => {
        const fetchMatches = async () => {
            if (userInfo && userInfo.id) {
                try {
                    setLoading(true);
                    const response = await axios.get(`https://readit-readit-com.onrender.com/api/matches`, {
                        params: {
                            userId: userInfo.id,
                            page,
                            limit: matchesPerPage,
                        },
                    });
                    setMatches(prevMatches => {
                        const newMatches = response.data.filter(match =>
                            !prevMatches.some(prevMatch => prevMatch.userId === match.userId)
                        );
                        return [...prevMatches, ...newMatches];
                    });
                    setLoading(false);
                } catch (err) {
                    setError(err.message);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchMatches();
    }, [userInfo, page]); // No need to check `authenticated` separately

    const loadMoreMatches = () => {
        setPage(prevPage => prevPage + 1);
    };

    if (loading && page === 1) return <div className="matches-table-container loading">Loading...</div>;
    if (error) return <div className="matches-table-container error">Error: {error}</div>;

    return (
        <div className="matches-table-container">
            <h2 className="custom-h2">Explore User Profiles That Align with Your Literary Palette</h2>
            {matches.length === 0 ? (
                <div className="no-matches-message">
                    <p>
                        Keep adding more books to your <span style={{ color: '#0071c5' }}>wishlist</span> and <span style={{ color: '#d13525' }}>read</span> books.
                    </p>
                    <img src={default_cover} alt="Default Cover" className="default-cover-image" /> {/* Use imported image */}
                    <p>You'll match soon with like-minded readers.</p>
                </div>
            ) : (
                <>
                    <table className="matches-table">
                        <thead>
                            <tr>
                                <th>Match Count</th>
                                <th>User Name</th>
                                <th>Profile Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            {matches.map(match => (
                                <tr key={match.userId}>
                                    <td>{match.score}</td>
                                    <td>{match.username}</td>
                                    <td><Link to={`/user/${match.userId}`}>View Profile</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {matches.length >= page * matchesPerPage && (
                        <div className="load-more-container">
                            <button onClick={loadMoreMatches}>Load More</button>
                        </div>
                    )}
                </>
            )}
            <div className="big_void"></div>
            <div className="big_void"></div>
        </div>
    );
};

export default AllMatchesTable;