import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from './UserContext';
import './Profile.css';
import checkBoxLogo from './images/check_box_logo.png';
import { format } from 'date-fns';
import FullStar from './icons/FullStar';
import HalfStar from './icons/HalfStar';
import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';

function UserProfile() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [completedBooks, setCompletedBooks] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [userReviews, setUserReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { setUserInfo, userInfo } = useContext(UserContext);

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const userResponse = await axios.get(`https://readit-readit-com.onrender.com/api/user/${userId}`);
        setUser(userResponse.data);

        const completedBooksResponse = await axios.get(`https://readit-readit-com.onrender.com/api/completed/${userId}`);
        const sortedCompletedBooks = completedBooksResponse.data.sort((a, b) => a.title.localeCompare(b.title));
        setCompletedBooks(sortedCompletedBooks);

        const wishlistItemsResponse = await axios.get(`https://readit-readit-com.onrender.com/api/wishlist/${userId}`);
        const sortedWishlistItems = wishlistItemsResponse.data.sort((a, b) => a.title.localeCompare(b.title));
        setWishlistItems(sortedWishlistItems);

        const reviewsResponse = await axios.get(`https://readit-readit-com.onrender.com/api/reviews/${userId}`);
        const sortedUserReviews = reviewsResponse.data.sort((a, b) => a.title.localeCompare(b.title));
        setUserReviews(sortedUserReviews);

        if (reviewsResponse.data.length > 0) {
          const totalRating = reviewsResponse.data.reduce((acc, review) => acc + review.rating, 0);
          setAverageRating((totalRating / reviewsResponse.data.length).toFixed(2));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    if (userId) {
      fetchUserProfile();
    }
  }, [userId]);

  useEffect(() => {
    async function fetchLoggedInUser() {
      try {
        const response = await axios.get('https://readit-readit-com.onrender.com/api/auth/check', { withCredentials: true });
        setUserInfo(response.data);
      } catch (err) {
        console.error('Failed to fetch logged-in user data:', err.message);
      }
    }

    fetchLoggedInUser();
  }, [setUserInfo]);

  const handleHelpfulClick = async (reviewId) => {
    if (!userInfo || !userInfo.id) {
      alert('Must be registered and logged in to mark reviews as helpful.');
      return;
    }

    try {
      const response = await axios.post(`https://readit-readit-com.onrender.com/api/reviews/${reviewId}/helpful`, {
        userId: userInfo.id
      });

      setUserReviews(prevReviews => prevReviews.map(review =>
        review._id === reviewId ? { ...review, helpfulCount: response.data.helpfulCount, usersWhoClicked: [...review.usersWhoClicked, userInfo.id] } : review
      ));
    } catch (error) {
      console.error('Error marking review as helpful:', error);
      alert(error.message);
    }
  };

  const getHelpfulText = (count) => {
    if (count === 0) return '';
    return `${count} reader${count > 1 ? 's' : ''} found this helpful`;
  };

  const renderStars = (rating, showNumber = false) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<FullStar key={`full-${i}`} className="star-icon" />);
    }
    if (halfStar) {
      stars.push(<HalfStar key="half" className="star-icon" />);
    }

    return (
      <p>
        {stars} {showNumber && `(${rating.toFixed(1)} out of 5)`}
      </p>
    );
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;
  if (!user) return <div className="loading">User not found</div>;

  const showProfileHeader = user.favoriteAuthor || user.favoriteBook || user.favoriteGenre;

  return (
    <div className="profile-container">
      <h1>Reader's Profile: {user.username}</h1>
      <CloudinaryContext cloudName="dk26qywhe">
              <Image 
              publicId={user.profileImage}
              className="user-profile-pic">
              <Transformation width="80" crop="scale" />
              </Image>
            </CloudinaryContext>
      {showProfileHeader && (
        <div className="profile-header">
          {user.favoriteAuthor && <p><strong>Favorite Author:</strong> {user.favoriteAuthor}</p>}
          {user.favoriteBook && <p><strong>Favorite Book:</strong> {user.favoriteBook}</p>}
          {user.favoriteGenre && <p><strong>Favorite Genre:</strong> {user.favoriteGenre}</p>}
        </div>
      )}

      <div className="my-completed-books">
        <h2><span style={{ color: '#d13525' }}>Read</span> Books</h2>
        <div className='check-box-note'>
          <img src={checkBoxLogo} alt="Checked Logo" className="check-box-logo" /> = Book discovered with readit-
          <span style={{ color: '#d13525' }}>readit</span>.com.
        </div>
        {completedBooks.length === 0 ? (
          <p>No completed books found.</p>
        ) : (
          <ul>
            {completedBooks.map((book, index) => (
              <li key={book._id || index}>
                <p>
                  <span style={{ fontStyle: 'italic' }}><strong>
                    <Link to={`/book/${book.bookId}`}>{book.title}</Link>
                  </strong></span> by {book.author_name}
                  {book.isChecked && <img src={checkBoxLogo} alt="Checked Logo" className="check-box-logo" />}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="my-wishlist">
        <h2><span style={{ color: '#0071c5' }}>Wishlisted</span> Books</h2>
        <div className='check-box-note'>
          <img src={checkBoxLogo} alt="Checked Logo" className="check-box-logo" /> = Book discovered with readit-
          <span style={{ color: '#d13525' }}>readit</span>.com.
        </div>
        {wishlistItems.length === 0 ? (
          <p>No items in the wishlist.</p>
        ) : (
          <ul>
            {wishlistItems.map((item, index) => (
              <li key={item._id || index}>
                <p>
                  <span style={{ fontStyle: 'italic' }}>
                    <strong>
                      <Link to={`/book/${item.bookId}`}>{item.title}</Link>
                    </strong>
                  </span> by {item.author_name}
                  {item.isChecked && <img src={checkBoxLogo} alt="Checked Logo" className="check-box-logo" />}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="my-reviews">
        <h2>Reviews by {user.username}</h2>
        <h4>Average Rating Score Given: {averageRating}</h4>
        {userReviews.length === 0 ? (
          <p>No reviews found.</p>
        ) : (
          <ul>
            {userReviews.map((review, index) => (
              <li key={review._id} className="review-item">
                <p className="review-link"><span style={{ fontStyle: 'italic' }}><strong><Link to={`/book/${review.bookId}`}>{review.title}</Link></strong></span> by {review.author_name}</p>

                <p>{renderStars(review.rating)}</p>
                <p><strong>{review.reviewTitle}</strong></p>
                <small className="review-date">Reviewed on {format(new Date(review.timestamp), 'MMMM d, yyyy')}</small>
                <div className="mini_void"></div>
                <p>{review.reviewText}</p>
                <div className="mini_void"></div>
                <button
                  onClick={() => handleHelpfulClick(review._id)}
                  disabled={userInfo && review.usersWhoClicked.includes(userInfo.id)}
                  className={userInfo && review.usersWhoClicked.includes(userInfo.id) ? 'button-helpful disabled' : 'button-helpful'}
                >
                  {userInfo && review.usersWhoClicked.includes(userInfo.id) ? 'Your feedback has been counted' : 'Mark this review as helpful'}
                </button>
                <small>{getHelpfulText(review.helpfulCount)}</small>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default UserProfile;
