import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './UserContext';
import './Profile.css';
import checkBoxLogo from './images/check_box_logo.png';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import FullStar from './icons/FullStar';
import HalfStar from './icons/HalfStar';
import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';
import CropModal from './CropModal';


const Profile = () => {

  const { userInfo, setUserInfo } = useContext(UserContext);
  const [editMode, setEditMode] = useState(false);
  const [favoriteAuthor, setFavoriteAuthor] = useState('');
  const [favoriteBook, setFavoriteBook] = useState('');
  const [favoriteGenre, setFavoriteGenre] = useState('');
  const [userReviews, setUserReviews] = useState([]);
  const [completedBooks, setCompletedBooks] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentReview, setCurrentReview] = useState(null);
  const [reviewText, setReviewText] = useState('');
  const [reviewTitle, setReviewTitle] = useState('');
  const [rating, setRating] = useState(0);
  const [checkedCompletedBooks, setCheckedCompletedBooks] = useState([]);
  const [checkedWishlistItems, setCheckedWishlistItems] = useState([]);
  const [file, setFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const MAX_FILE_SIZE = 314 * 1024;



  useEffect(() => {
    const fetchData = async () => {
      if (userInfo?.id) { // Optional chaining to ensure userInfo and userInfo.id are defined
        setIsLoading(true);
        setError(null);
  
        try {
          const [userResponse, reviewsResponse, completedBooksResponse, wishlistItemsResponse] = await Promise.all([
            fetch(`https://readit-readit-com.onrender.com/api/user/${userInfo.id}`, { credentials: 'include' }),
            fetch(`https://readit-readit-com.onrender.com/api/reviews?userId=${userInfo.id}`, { credentials: 'include' }),
            fetch(`https://readit-readit-com.onrender.com/api/completed/${userInfo.id}`, { credentials: 'include' }),
            fetch(`https://readit-readit-com.onrender.com/api/wishlist/${userInfo.id}`, { credentials: 'include' }),
          ]);
  
          if (!userResponse.ok) throw new Error('Failed to fetch user profile');
          if (!reviewsResponse.ok) throw new Error('Failed to fetch reviews');
          if (!completedBooksResponse.ok) throw new Error('Failed to fetch completed books');
          if (!wishlistItemsResponse.ok) throw new Error('Failed to fetch wishlist items');
  
          const user = await userResponse.json();
          const reviews = await reviewsResponse.json();
          const completedBooks = await completedBooksResponse.json();
          const wishlistItems = await wishlistItemsResponse.json();

          
  
          // Check if the data has actually changed before updating state
          if (userInfo.favoriteAuthor !== user.favoriteAuthor || userInfo.favoriteBook !== user.favoriteBook || userInfo.favoriteGenre !== user.favoriteGenre || userInfo.profileImage !== user.profileImage) {
            setUserInfo(prev => ({
              ...prev,
              favoriteAuthor: user.favoriteAuthor,
              favoriteBook: user.favoriteBook,
              favoriteGenre: user.favoriteGenre,
              profileImage: user.profileImage
            }));
          }
  
          // Update state with fetched data
          setFavoriteAuthor(userInfo.favoriteAuthor || '');
          setFavoriteBook(userInfo.favoriteBook || '');
          setFavoriteGenre(userInfo.favoriteGenre || '');
          setUserReviews(reviews);
          setCompletedBooks(completedBooks);
          setWishlistItems(wishlistItems);
  
          // Calculate average rating
          if (reviews.length > 0) {
            const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
            setAverageRating((totalRating / reviews.length).toFixed(2));
          }
  
          // Set checked completed books and wishlist items
          const checkedCompletedBooks = completedBooks.filter(book => book.isChecked).map(book => book._id);
          setCheckedCompletedBooks(checkedCompletedBooks);
  
          const checkedWishlistItems = wishlistItems.filter(item => item.isChecked).map(item => item._id);
          setCheckedWishlistItems(checkedWishlistItems);
  
        } catch (error) {
          console.error('Error fetching data:', error);
          setError(error);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    fetchData();
  }, [userInfo, setUserInfo, userInfo?.id]); // Added dependencies

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  
    // Check if the file is larger than the maximum allowed size
    if (selectedFile && selectedFile.size > MAX_FILE_SIZE) {
      alert(`File size exceeds the maximum allowed size of ${MAX_FILE_SIZE / 1024} KB. Please choose a smaller file.`);
      return;
    }
  
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setIsCropModalOpen(true);
      };
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile); // Set file here
    }
  };

  const handleCropComplete = async (croppedImgBase64) => {
    // Convert base64 to Blob
    const response = await fetch(croppedImgBase64);
    const blob = await response.blob();
    
    // Convert Blob to File
    const file = new File([blob], 'profile-image.jpg', { type: 'image/jpeg' });
    setFile(file); // Now you have a File object named file
    setIsCropModalOpen(false);  // Close the modal after cropping
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please crop the image before uploading.');
      return;
    }

    const formData = new FormData();
    formData.append('profileImage', file); // This is the File object
    formData.append('userId', userInfo.id);

    try {
      const response = await fetch('https://readit-readit-com.onrender.com/api/user/upload-profile-image', {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to upload image');
      }

      const updatedUser = await response.json();
      setUserInfo((prev) => ({
        ...prev,
        profileImage: updatedUser.filePath,
      }));
      setFile(null); // Clear the file after successful upload
    } catch (error) {
      console.error('Error uploading file:', error);
      setError(error);
    }
  };

  const handleCompletedBookCheckboxChange = async (bookId) => {
    const isChecked = checkedCompletedBooks.includes(bookId);
    const updatedBooks = isChecked
      ? checkedCompletedBooks.filter(id => id !== bookId)
      : [...checkedCompletedBooks, bookId];
  
    setCheckedCompletedBooks(updatedBooks);
  
    // Send update request to backend
    try {
      const response = await fetch(`https://readit-readit-com.onrender.com/api/completed/${bookId}/check`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ isChecked: !isChecked }), // Toggle the checked state
      });
  
      if (!response.ok) {
        throw new Error('Failed to update completed book');
      }
    } catch (error) {
      console.error('Error updating completed book:', error);
    }
  };

  const handleWishlistItemCheckboxChange = async (itemId) => {
    const isChecked = checkedWishlistItems.includes(itemId);
    const updatedItems = isChecked
      ? checkedWishlistItems.filter(id => id !== itemId)
      : [...checkedWishlistItems, itemId];
  
    setCheckedWishlistItems(updatedItems);
  
    // Send update request to backend
    try {
      const response = await fetch(`https://readit-readit-com.onrender.com/api/wishlist/${itemId}/check`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ isChecked: !isChecked }), // Toggle the checked state
      });
  
      if (!response.ok) {
        throw new Error('Failed to update wishlist item');
      }
    } catch (error) {
      console.error('Error updating wishlist item:', error);
    }
  };
  
  const handleSave = async () => {
    try {
      const updatedData = {
        favoriteAuthor,
        favoriteBook,
        favoriteGenre,
      };
  
      const response = await fetch(`https://readit-readit-com.onrender.com/api/user/${userInfo.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(updatedData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update user info');
      }
  
      const updateUserInfo = { ...userInfo, ...updatedData };
      setUserInfo(updateUserInfo);
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user info:', error);
      setError(error.message || 'An unexpected error occurred');
    }
  };



  
  const deleteUserReview = async (reviewId) => {
    if (window.confirm('Are you sure you want to delete this review?')) {
      try {
        const response = await fetch(`https://readit-readit-com.onrender.com/api/reviews/${reviewId}`, {
          method: 'DELETE',
          credentials: 'include',
        });

        if (response.ok) {
          setUserReviews(userReviews.filter(review => review._id !== reviewId));
        } else {
          setError(new Error('Failed to delete review'));
        }
      } catch (error) {
        setError(error);
      }
    }
  };

  const openEditModal = (review) => {
    setCurrentReview(review);
    setReviewText(review.reviewText);
    setReviewTitle(review.reviewTitle);
    setRating(review.rating);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentReview(null);
  };

  const handleModalSubmit = async (e) => {
    e.preventDefault();
    if (currentReview) {
      try {
        const updatedReview = { ...currentReview, reviewText, rating, reviewTitle };
        const response = await fetch(`https://readit-readit-com.onrender.com/api/reviews/${currentReview._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(updatedReview),
        });

        if (response.ok) {
          const updatedReviews = userReviews.map(review =>
            review._id === currentReview._id ? updatedReview : review
          );
          setUserReviews(updatedReviews);
          handleModalClose();
        } else {
          setError(new Error('Failed to update review'));
        }
      } catch (error) {
        setError(error);
      }
    }
  };

  const getHelpfulText = (count) => {
    if (count === 0) return '';
    return `${count} reader${count > 1 ? 's' : ''} found this helpful`;
  };
  

  const renderStars = (rating, showNumber = false) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
  
    for (let i = 0; i < fullStars; i++) {
      stars.push(<FullStar key={`full-${i}`} className="star-icon" />);
    }
    if (halfStar) {
      stars.push(<HalfStar key="half" className="star-icon" />);
    }
  
    return (
      <p>
        {stars} {showNumber && `(${rating.toFixed(1)} out of 5)`}
      </p>
    );
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  
  
  return (
    <div className="profile-container">
      {userInfo && (
        <div>
          <div className="profile-header">
            <h1>Welcome, {userInfo.username}</h1>
            <CloudinaryContext cloudName="dk26qywhe">
            <Image
              publicId={userInfo.profileImage}
              className="user-profile-pic" // Add the class here
            >
              <Transformation width="120" crop="scale" />
            </Image>
          </CloudinaryContext>
            <p></p>
            <input className="pic-upload-button"type="file" onChange={handleFileChange} />
            <p></p>
            <button className="pic-upload-button" onClick={handleUpload}>Upload</button>
            <p><strong>Favorite Book:</strong> {editMode ? (
              <input type="text" value={favoriteBook} onChange={(e) => setFavoriteBook(e.target.value)} />
            ) : (
              favoriteBook || 'Not specified'
            )}</p>
            <p><strong>Favorite Author:</strong> {editMode ? (
              <input type="text" value={favoriteAuthor} onChange={(e) => setFavoriteAuthor(e.target.value)} />
            ) : (
              favoriteAuthor || 'Not specified'
            )}</p>
            <p><strong>Favorite Genre:</strong> {editMode ? (
              <input type="text" value={favoriteGenre} onChange={(e) => setFavoriteGenre(e.target.value)} />
            ) : (
              favoriteGenre || 'Not specified'
            )}</p>
            {editMode ? (
              <button onClick={handleSave}>Save</button>
            ) : (
              <button onClick={() => setEditMode(true)}>Edit Favorites</button>
            )}
          </div>

          <div className="my-completed-books">
            <h2>My <span style={{ color: '#d13525' }}>Read</span> Books</h2>
            <div className='check-box-note'>
              Please check boxes for books readit-
              <span style={{ color: '#d13525' }}>readit</span>.com helped you discover.
            </div>
            {completedBooks.length === 0 ? (
              <p>No completed books found.</p>
            ) : (
              <ul>
                {completedBooks.map(book => (
                  <li key={book._id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={checkedCompletedBooks.includes(book._id)}
                        onChange={() => handleCompletedBookCheckboxChange(book._id)}
                      />
                      <span style={{ fontStyle: 'italic' }}><strong>
                        <Link to={`/book/${book.bookId}`}>{book.title}</Link>
                      </strong></span> by {book.author_name}
                      {checkedCompletedBooks.includes(book._id) && <img src={checkBoxLogo} alt="Check Box Logo" className="check-box-logo" />}
                    </label>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {/* #0071c5 #0071c5 #0070BB #4682B4 #3E8EDE*/}
          <div className="my-wishlist">
            <h2>My <span style={{ color: '#0071c5' }}>Wishlist</span> Books </h2>
            <div className='check-box-note'>
              Please check boxes for books readit-
              <span style={{ color: '#d13525' }}>readit</span>.com helped you discover.
            </div>
            {wishlistItems.length === 0 ? (
              <p>No wishlist items found.</p>
            ) : (
              <ul>
                {wishlistItems.map(item => (
                  <li key={item._id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={checkedWishlistItems.includes(item._id)}
                        onChange={() => handleWishlistItemCheckboxChange(item._id)}
                      />
                      <span style={{ fontStyle: 'italic' }}>
                        <strong>
                          <Link to={`/book/${item.bookId}`}>{item.title}</Link>
                        </strong>
                      </span> by {item.author_name}
                      {checkedWishlistItems.includes(item._id) && <img src={checkBoxLogo} alt="Check Box Logo" className="check-box-logo" />}
                    </label>
                </li>
                ))}
              </ul>
            )}
          </div>

          <div className="my-reviews">
            <h2>My Reviews</h2>
            <h4>Average Rating Score Given: {averageRating}</h4>
            {userReviews.length === 0 ? (
              <p>No reviews found.</p>
            ) : (
              <ul>
                {userReviews.map(review => (
                  <li key={review._id} className="review-item">
                    <p className="review-link"><span style={{ fontStyle: 'italic' }}><strong><Link to={`/book/${review.bookId}`}>{review.title}</Link></strong></span> by {review.author_name}</p>
                    <p>{renderStars(review.rating)}</p>
                    <p><strong>{review.reviewTitle}</strong></p>
                    <small className="review-date">Reviewed on {format(new Date(review.timestamp), 'MMMM d, yyyy')}</small>
                    <div className="mini_void"></div>
                    <p>{review.reviewText}</p>
                    <div className="mini_void"></div>
                    <button onClick={() => deleteUserReview(review._id)}>Delete</button>
                    <button onClick={() => openEditModal(review)}>Edit</button>
                    {review.helpfulCount > 0 && <p className='helpfulText'>{getHelpfulText(review.helpfulCount)}</p>}
                  </li>
                  
                ))}
              </ul>
            )}
          </div>

          {isModalOpen && currentReview && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={handleModalClose}>&times;</span>
                <h2>Edit Review: {currentReview.title}</h2>
                <form onSubmit={handleModalSubmit}>
                  <div className="rating-container">
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <span
                          key={index}
                          className={index <= rating ? 'star-filled' : 'star'}
                          onClick={() => setRating(index)}
                        >
                          &#9733;
                        </span>
                      );
                    })}
                  </div>
                  <input
                type="text"
                className="review-title"
                value={reviewTitle}
                onChange={(e) => setReviewTitle(e.target.value)}
                placeholder="Review Title"
                maxLength="50"
              />
              <div className="character-count-title2">{reviewTitle.length}/50</div>
              <p> </p>
                  <textarea
                    className="review-text"
                    value={reviewText}
                    onChange={(e) => setReviewText(e.target.value.slice(0, 500))}
                    maxLength="500"
                  />
                  <div className="character-count">{reviewText.length}/500</div>
                  <button type="submit">Submit</button>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    



{isCropModalOpen && (
    <div>
    <button onClick={() => setIsCropModalOpen(true)}>Open Crop Modal</button>
    {isCropModalOpen && (
      <CropModal
        imageSrc={imageSrc}
        onClose={() => setIsCropModalOpen(false)}
        onCropComplete={handleCropComplete}
      />
    )}
  </div>
  )}
    </div>
  );  
}


export default Profile;
