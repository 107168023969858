import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './ResetPassword.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    try {
      await axios.post('https://readit-readit-com.onrender.com/api/reset-password', { token, newPassword });
      alert('Password reset successful.');
      // Clear form fields
      setNewPassword('');
      setConfirmPassword('');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Request failed. Please try again.');
    }
  };

  return (
    <div className="reset-password-container">
      <form className="reset-password" onSubmit={handleSubmit}>
        <h1>Reset Password</h1>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="input-container">
          <input
            type={showNewPassword ? 'text' : 'password'}
            className="reset-password-input"
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <span
            className="toggle-password"
            onClick={() => setShowNewPassword(!showNewPassword)}
          >
            {showNewPassword ? <FaEye /> : <FaEyeSlash />}
          </span>
        </div>
        <div className="input-container">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            className="reset-password-input"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <span
            className="toggle-password"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
          </span>
        </div>
        <button type="submit" className="reset-password-button">Submit</button>
      </form>
    </div>
  );
};

export default ResetPassword;
