import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './UserContext';
import { useNavigate, Link } from 'react-router-dom';
import './Wishlist.css';

const Wishlist = () => {
  const { userInfo } = useContext(UserContext);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('first-added'); // Default sort by first-added
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    if (!userInfo?.id) {
      navigate('/login'); // Redirect to login if user is not authenticated
      return;
    }

    const fetchWishlistItems = async () => {
      try {
        const response = await fetch(`https://readit-readit-com.onrender.com/api/wishlist/${userInfo.id}`, {
          credentials: 'include',
        });
        if (response.ok) {
          const items = await response.json();
          setWishlistItems(items);
        } else {
          setError(new Error('Failed to fetch wishlist items'));
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWishlistItems();
  }, [userInfo, navigate]);
  

  const handleRemoveFromWishlist = async (itemId) => {
    if (window.confirm('Are you sure you want to delete this item from your wishlist?')) {
      try {
        const response = await fetch(`https://readit-readit-com.onrender.com/api/wishlist/${itemId}`, {
          method: 'DELETE',
          credentials: 'include',
        });
        if (response.ok) {
          setWishlistItems(wishlistItems.filter(item => item._id !== itemId));
        } else {
          setError(new Error('Failed to remove item from wishlist'));
        }
      } catch (error) {
        setError(error);
      }
    }
  };

  const handleMoveToCompleted = async (book) => {
    try {
      const response = await fetch(`https://readit-readit-com.onrender.com/api/completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          userId: userInfo.id,
          bookId: book.bookId,
          title: book.title,
          author_name: book.author_name,
          cover_url: book.cover_url,
          isChecked: book.isChecked,
        }),
      });
      if (response.ok) {
        await handleDeleteWithoutAlert(book._id);
        alert('Book successfully moved to Read list');
      } else {
        setError(new Error('Failed to mark item as read'));
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleDeleteWithoutAlert = async (itemId) => {
    try {
      const response = await fetch(`https://readit-readit-com.onrender.com/api/wishlist/${itemId}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {
        setWishlistItems(wishlistItems.filter(item => item._id !== itemId));
      } else {
        setError(new Error('Failed to remove item from wishlist'));
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const sortedItems = (() => {
    switch (sortBy) {
      case 'first-added':
        return [...wishlistItems].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      case 'last-added':
        return [...wishlistItems].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      case 'title-az':
        return [...wishlistItems].sort((a, b) => a.title.localeCompare(b.title));
      default:
        return wishlistItems;
    }
  })();

  if (isLoading) {
    return <div className="wishlist-container loading">Loading...</div>;
  }

  if (error) {
    return <div className="wishlist-container error">Error: {error.message}</div>;
  }

  return (
    <div className="wishlist-container">
      <h2>My Books <span style={{ color: '#0071c5' }}>Wishlisted</span></h2>
      
      <div className="sort-controls">
        <label htmlFor="sort">Sort by:</label>
        <select id="sort" value={sortBy} onChange={handleSortChange}>
          <option value="first-added">First Added</option>
          <option value="last-added">Last Added</option>
          <option value="title-az">Title A-Z</option>
        </select>
      </div>
      
      {sortedItems.length === 0 ? (
        <div className="no-items-message">No items in the wishlist.</div>
      ) : (
        <div className="wishlist-items-container">
          {sortedItems.map(item => (
            <div className="wishlist-book-item" key={item._id}>
              <h3>
                <Link to={`/book/${item.bookId}`}>{item.title}</Link>
              </h3>
              <p>Author: {item.author_name}</p>
              <div className="book-cover-container">
                {item.cover_url ? (
                  <img src={item.cover_url} alt="Book Cover" className="book-cover" />
                ) : (
                  <div className="book-cover-placeholder">No Cover Available</div>
                )}
              </div>
              <div className="button-container">
                <button className="remove-button" onClick={() => handleRemoveFromWishlist(item._id)}>Delete</button>
                <button className="move-to-completed-button" onClick={() => handleMoveToCompleted(item)}>Move to Read</button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Wishlist;
