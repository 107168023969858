import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from './UserContext';
import './Header.css'; // Import the CSS file

const Header = () => {
  const { userInfo, setUserInfo, loading } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Handle loading and null userInfo
  if (loading) {
    return <div>Loading...</div>; // Optionally, add a loading state if needed
  }

  // Check if userInfo is null
  const isUserLoggedIn = userInfo && userInfo.username;

  const logoutAndRedirect = async () => {
    try {
      const response = await fetch('https://readit-readit-com.onrender.com/api/logout', {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        setUserInfo(null); // Clear userInfo on logout
        localStorage.removeItem('token');
        window.location.href = '/';
      } else {
        console.error('Error logging out:', response.statusText);
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header>
      <Link to="/" onClick={closeMenu}>
        <img src="/images/latest_logo.png" alt="Logo" className="logo" />
      </Link>
      <button className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        ☰
      </button>
      <nav className={isMenuOpen ? 'open' : ''}>
        <ul>
          <li>
            <Link to="/search" onClick={closeMenu}>Search</Link>
          </li>
          <li>
            <Link to="/wishlist" onClick={closeMenu}>Wishlist</Link>
          </li>
          <li>
            <Link to="/completed" className="read-link" onClick={closeMenu}>Read</Link>
          </li>
          <li>
            <Link to="/wishlistTotals" onClick={closeMenu}>Most Wishlisted</Link>
          </li>
          <li>
            <Link to="/completedTotals" className="read-link" onClick={closeMenu}>Most Read</Link>
          </li>
          {/* Profile and Logout button */}
          {isUserLoggedIn && (
            <>
              <li>
                <Link to="/profile" onClick={closeMenu}>Profile</Link>
              </li>
              <li>
                <button className="btn btn-primary display-7" onClick={() => { logoutAndRedirect(); closeMenu(); }}>
                  Log Out
                </button>
              </li>
            </>
          )}
          {/* Register and Login links */}
          {!isUserLoggedIn && (
            <>
              <li>
                <Link to="/register" onClick={closeMenu}>Register</Link>
              </li>
              <li>
                <Link to="/login" onClick={closeMenu}>Login</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
