import React from 'react';
import './Footer.css'; // Ensure you have the corresponding CSS file
import defaultCover from './images/default_cover2.png'; // Import the image
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>About Us</h4>
          <p>We help readers find books they love.</p>
         
        </div>
        <div className="footer-section">
          {/* <h4>Resources</h4>
          <ul>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/terms">Terms of Service</Link></li>
          </ul> */}
        </div>
        <div className="footer-section">
          {/* <h4>Follow Us</h4>
          <p>Coming soon...</p> */}
          <div className="social-icons">
            <a href="https://facebook.com" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
            <a href="https://twitter.com" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
            <a href="https://linkedin.com" aria-label="LinkedIn"><i className="fab fa-linkedin-in"></i></a>
            <a href="https://instagram.com" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
        <div className="footer-section">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="https://www.pbs.org/the-great-american-read/books/#/" target="_blank" rel="noopener noreferrer">PBS Read the 100 List</a></li>
            {/* <li><a href="https://www.rd.com/list/books-read-before-die/" target="_blank" rel="noopener noreferrer">Reader's Digest 100</a></li> */}
            <li><a href="https://thegreatestbooks.org/" target="_blank" rel="noopener noreferrer">The Greatest Books</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-image-container">
        <Link to="/">
          <img src={defaultCover} alt="Default Cover" className="footer-cover-image" />
        </Link>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 readit-
        <span style={{ color: '#d13525' }}>readit</span>.com. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
