import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ConfirmEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      const token = new URLSearchParams(location.search).get('token');
      if (!token) {
        navigate('/login'); // Redirect to login if no token
        return;
      }

      try {
        const response = await fetch(`https://readit-readit-com.onrender.com/api/confirm-email?token=${token}`);
        if (!response.ok) {
          throw new Error('Email confirmation failed');
        }
        const data = await response.json();
        console.log('Email confirmation successful:', data.message);
        navigate('/login'); // Redirect to login page after confirmation
      } catch (error) {
        console.error('Error confirming email:', error);
        navigate('/login'); // Redirect to login page on error
      }
    };

    confirmEmail();
  }, [location.search, navigate]);

  return <div>Redirecting...</div>; // Optional message for feedback
};

export default ConfirmEmail;