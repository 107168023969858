import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './CompletedTotals.css';

const CompletedTotals = () => {
  useParams();
  const [completedTotals, setCompletedTotals] = useState([]);
  const [allTotals, setAllTotals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeframe, setTimeframe] = useState('allTime');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  useEffect(() => {
    const fetchCompletedTotals = async () => {
      setIsLoading(true);
      setError(null); // Reset error before fetching

      try {
        const response = await fetch(`https://readit-readit-com.onrender.com/api/completedTotals?timeframe=${timeframe}`, {
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch completed totals');
        }

        const data = await response.json();

        // Fetch detailed book data for each item
        const detailedData = await Promise.all(data.map(async item => {
          const bookResponse = await fetch(`https://readit-readit-com.onrender.com/api/book/id/${item.bookId}`);
          const bookData = await bookResponse.json();

          return {
            ...item,
            title: bookData.book.title,
            author_name: bookData.book.author_name,
            cover_url: bookData.book.cover_url,
          };
        }));

        setAllTotals(detailedData);
        setCompletedTotals(detailedData.slice(0, itemsPerPage));
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompletedTotals();
  }, [timeframe, itemsPerPage]);

  const handleTimeframeChange = (newTimeframe) => {
    setTimeframe(newTimeframe);
    setCurrentPage(1);
  };

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const newItems = allTotals.slice(currentPage * itemsPerPage, nextPage * itemsPerPage);
    setCompletedTotals([...completedTotals, ...newItems]);
    setCurrentPage(nextPage);
  };

  if (isLoading) {
    return <div className="completed-totals-container loading">Loading...</div>;
  }

  if (error) {
    return <div className="completed-totals-container error">Error: {error.message}</div>;
  }

  return (
    <div className="completed-totals-container">
      <h2>
        Most <span style={{ color: '#d13525' }}>Read</span> Books
      </h2>
      <div className="timeframe-filters">
        {['thisWeek', 'thisMonth', 'allTime'].map((period) => (
          <button
            key={period}
            className={timeframe === period ? 'active' : ''}
            onClick={() => handleTimeframeChange(period)}
          >
            {period === 'thisWeek' ? 'This Week' : period === 'thisMonth' ? 'This Month' : 'All Time'}
          </button>
        ))}
      </div>
      <table className="completed-table">
        <thead>
          <tr>
            <th>Count</th>
            <th></th>
            <th>Title</th>
            <th>Author</th>
          </tr>
        </thead>
        <tbody>
          {completedTotals.map((item) => (
            <tr key={item.bookId}>
              <td>{item.count}</td>
              <td>
                <Link to={`/book/${item.bookId}`}>
                  <img src={item.cover_url || '/path/to/default/cover.png'} alt={item.title} className="book-cover" />
                </Link>
              </td>
              <td>
                <Link to={`/book/${item.bookId}`}>{item.title}</Link>
              </td>
              <td>{item.author_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {completedTotals.length < allTotals.length && (
        <div className="pagination-controls">
          <button onClick={handleLoadMore}>Load More</button>
        </div>
      )}
      <div className="big_void"></div>
    </div>
  );
};

export default CompletedTotals;
