import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './UserContext';
import { useNavigate, Link } from 'react-router-dom';
import './Completed.css';

const Completed = () => {
  const { userInfo } = useContext(UserContext);
  const [completedItems, setCompletedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [userReviews, setUserReviews] = useState([]);
  const [author_name, setAuthorName] = useState('');
  const [title, setTitle] = useState('');
  const [reviewTitle, setReviewTitle] = useState('');
  const [sortCriteria, setSortCriteria] = useState('first-added');
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo?.id) {
      navigate('/login');
      return;
    }

    const fetchCompletedItems = async () => {
      try {
        const response = await fetch(`https://readit-readit-com.onrender.com/api/completed/${userInfo.id}`, {
          credentials: 'include',
        });
        if (response.ok) {
          const items = await response.json();
          setCompletedItems(items);
        } else {
          setError(new Error('Failed to fetch completed items'));
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompletedItems();
  }, [userInfo, navigate]);

  const fetchUserReviews = async () => {
    try {
      if (userInfo?.id) {
        const response = await fetch(`https://readit-readit-com.onrender.com/api/reviews/${userInfo.id}`, {
          credentials: 'include',
        });
        if (response.ok) {
          const reviews = await response.json();
          setUserReviews(reviews);
        } else {
          setError(new Error('Failed to fetch reviews'));
        }
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleModalOpen = (bookId, authorName, title) => {
    setSelectedBookId(bookId);
    setAuthorName(authorName);
    setTitle(title);
    setReviewTitle('');
    setIsModalOpen(true);
    fetchUserReviews();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setReview(''); // Reset review
    setRating(0);  // Reset rating
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (rating === 0) {
      alert("Please select number of stars for your review.");
      return;
    }

    const existingReview = userReviews.find(review => review.bookId === selectedBookId);

    if (existingReview) {
      alert("You have already reviewed this book. Please edit your current review on your profile page.");
      return;
    }

    try {
      const response = await fetch('https://readit-readit-com.onrender.com/api/reviews', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          userId: userInfo.id,
          bookId: selectedBookId,
          reviewText: review,
          reviewTitle: reviewTitle.trim(),
          rating,
          author_name: author_name.trim(), 
          title,
        }),
      });

      if (response.ok) {
        handleModalClose();
        alert('Review submitted successfully!');
      } else {
        setError(new Error('Failed to submit review'));
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleRemoveFromCompleted = async (itemId) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        const response = await fetch(`https://readit-readit-com.onrender.com/api/completed/${itemId}`, {
          method: 'DELETE',
          credentials: 'include',
        });

        if (response.ok) {
          setCompletedItems(completedItems.filter(item => item._id !== itemId));
          alert('Item removed successfully!');
        } else {
          setError(new Error('Failed to remove item'));
        }
      } catch (error) {
        setError(error);
      }
    }
  };

  const sortCompletedItems = (items) => {
    switch (sortCriteria) {
      case 'first-added':
        return [...items].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      case 'last-added':
        return [...items].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      case 'title-az':
        return [...items].sort((a, b) => a.title.localeCompare(b.title));
      default:
        return items;
    }
  };

  const sortedCompletedItems = sortCompletedItems(completedItems);

  if (isLoading) {
    return <div className="completed-container loading">Loading...</div>;
  }

  if (error) {
    return <div className="completed-container error">Error: {error.message}</div>;
  }

  return (
    <div className="completed-container">
      <h2>My Books <span style={{ color: '#d13525' }}>Read</span></h2>
      <div className="sort-controls">
        <label htmlFor="sort">Sort by:</label>
        <select id="sort" value={sortCriteria} onChange={(e) => setSortCriteria(e.target.value)}>
          <option value="first-added">First Added</option>
          <option value="last-added">Last Added</option>
          <option value="title-az">Title A-Z</option>
        </select>
      </div>
      {sortedCompletedItems.length === 0 ? (
        <div className="no-items-message">No items in the completed list.</div>
      ) : (
        <div className="completed-items-container">
          {sortedCompletedItems.map(item => (
            <div className="completed-book-item" key={item._id}>
              <h3>
                <Link to={`/book/${item.bookId}`}>{item.title}</Link>
              </h3>
              <p>Author: {item.author_name}</p>
              <div className="book-cover-container">
                {item.cover_url ? (
                  <img src={item.cover_url} alt="Book Cover" className="book-cover" />
                ) : (
                  <div className="book-cover-placeholder">No Cover Available</div>
                )}
              </div>
              <div className="button-container">
                <button className="remove-button" onClick={() => handleRemoveFromCompleted(item._id)}>Delete</button>
                <button className="add-review-button" onClick={() => handleModalOpen(item.bookId, item.author_name, item.title)}>Add Review</button>
              </div>
            </div>
          ))}
        </div>
      )}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleModalClose}>&times;</span>
            <h2>Review: {title}</h2>
            <form onSubmit={handleSubmit}>
              <div className="rating-container">
                {[...Array(5)].map((_, index) => {
                  const starIndex = index + 1;
                  return (
                    <span
                      key={starIndex}
                      className={starIndex <= rating ? 'star-filled' : 'star'}
                      onClick={() => setRating(starIndex)}
                    >
                      &#9733;
                    </span>
                  );
                })}
              </div>
              <input
                type="text"
                className="review-title"
                value={reviewTitle}
                onChange={(e) => setReviewTitle(e.target.value)}
                placeholder="Review Title"
                maxLength="50"
              />
              <div className="character-count-title2">{reviewTitle.length}/50</div>
              <p> </p>
              <textarea
                className="review-text"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                placeholder="Write your review here..."
                maxLength="500"
              />
              <div className="character-count">{review.length}/500</div>
              <button className="submit-review-btn" type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Completed;
